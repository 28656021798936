import { css } from 'styled-components'

/**
 * Colours
 * Figma: https://tinyurl.com/ye2ynxnz
 */
export const colours = css`
  /* Colour definitions */
  --blue-300: hsla(229, 12%, 34%, 1);
  --blue-400: hsla(228, 22%, 23%, 1);
  --blue-400-70: hsla(228, 22%, 23%, 0.7);
  --blue-400-15: hsla(228, 22%, 23%, 0.15);
  --blue-500: hsla(228, 22%, 18%, 1);

  --cream-100: hsla(36, 33%, 97%, 1);

  --dark-neutral-700: hsla(195, 2%, 44%, 1);

  --indigo-400: hsla(241, 55%, 57%, 1);
  --indigo-500: hsla(241, 61%, 47%, 1);

  --light-neutral-100: hsla(0, 0%, 100%, 1);
  --light-neutral-200: hsla(0, 0%, 97%, 1);
  --light-neutral-400: hsla(0, 0%, 89%, 1);

  --red-100: hsla(11, 65%, 90%, 1);
  --red-400: hsla(10, 68%, 44%, 1);

  --score-excellent: hsla(67, 98%, 34%, 1);

  --teal-100: hsla(170, 27%, 96%, 1);

  --lime-500: hsla(81, 32%, 74%, 1);

  --transparent: transparent;

  --yellow-400: hsla(49, 100%, 50%, 1);
  --yellow-500: hsla(44, 77%, 58%, 1);

  --green-400: hsla(139, 47%, 34%, 1);

  --white-transparent: hsla(0, 0%, 100%, 0.25);

  /* Text - main */
  --colour-text-primary: var(--blue-400);
  --colour-text-primary-inverse: var(--light-neutral-100);
  --colour-text-secondary: var(--blue-300);
  --colour-text-secondary-inverse: var(--light-neutral-400);
  --colour-text-placeholder: var(--dark-neutral-700);

  /* Text - state */
  --colour-text-error: var(--red-400);

  /* Text - link */
  --colour-text-link: var(--indigo-400);
  --colour-text-link-hover: var(--indigo-500);
  --colour-text-link-inverse: var(--light-neutral-100);
  --colour-text-link-inverse-hover: var(--light-neutral-200);
  --colour-text-link-navigation-bold: var(--blue-400);
  --colour-text-link-navigation-bold-hover: var(--indigo-500);

  /* Surface - main */
  --colour-surface-neutral: var(--light-neutral-100);
  --colour-surface-neutral-disabled: var(--light-neutral-200);
  --colour-surface-yellow-bold: var(--yellow-400);
  --colour-surface-navy-bold: var(--blue-400);
  --colour-surface-aubergine-bold: hsla(336, 30%, 25%, 1);
  --colour-surface-teal-bold: hsla(180, 19%, 22%, 1);

  /* Surface - decorative */
  --colour-surface-cream-subtle: hsla(53, 36%, 96%, 1);
  --colour-surface-cream-bold: hsla(53, 43%, 89%, 1);
  --colour-surface-yellow-subtle: hsla(49, 100%, 86%, 1);
  --colour-surface-navy-subtle: hsla(229, 52%, 96%, 1);
  --colour-surface-aubergine-subtle: hsla(353, 31%, 94%, 1);
  --colour-surface-teal-subtle: hsla(165, 31%, 95%, 1);
  --colour-surface-teal-medium: hsla(162, 30%, 83%, 1);

  /* Surface - overlay */
  --colour-surface-blanket: var(--blue-400-70);

  /* Surface - label */
  --colour-surface-label-green: var(--score-excellent);

  /* Border - default */
  --colour-border: var(--light-neutral-400);
  --colour-border-secondary: var(--blue-400);
  --colour-border-inverse: var(--light-neutral-100);
  --colour-border-teal-bold: hsla(180, 19%, 22%, 0.1);

  /* Border - state */
  --colour-border-disabled: var(--light-neutral-200);
  --colour-border-focused: var(--indigo-400);
  --colour-border-error: var(--red-400);
  --colour-border-blue-subtle: var(--blue-400-15);

  /* Icon tokens */
  --colour-icon-primary: var(--blue-400);
  --colour-icon-border: var(--blue-400);
  --colour-icon-secondary: var(--dark-neutral-700);
  --colour-icon-inverse: var(--light-neutral-100);
  --colour-icon-success: var(--green-400);
  --colour-icon-error: var(--red-400);
  --colour-icon-brand: var(--yellow-400);
  --colour-icon-lime: var(--lime-500);

  /* Divider */
  --colour-divider-primary: var(--light-neutral-400);
  --colour-divider-secondary: hsla(0, 0%, 89%, 0.25);
  --colour-divider-tertiary: var(--colour-border-blue-subtle);
  --colour-divider-brand: var(--colour-surface-yellow-bold);
`
