import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { buttonStyles } from './ds/buttonStyles'
import { colours } from './ds/colourStyles'
import { formFieldStyles } from './ds/formFieldStyles'
import { gridSpacing } from './ds/gridSpacingStyles'
import { radius } from './ds/radiusStyles'
import { theme } from './theme'
import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { isTestKitPaymentElementEnabled } from '@/lib/utils/flags'

const isPaymentElementEnabled = isTestKitPaymentElementEnabled()

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family:'Nantes';
    src: url('/fonts/Nantes_Web_Book/NantesWeb-Book.woff2') format('woff2'),
      url('/fonts/Nantes_Web_Book/NantesWeb-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family:'Nantes-Italic';
    src: url('/fonts/Nantes_Web_BookItalic/NantesWeb-BookItalic.woff2') format('woff2'),
      url('/fonts/Nantes_Web_BookItalic/NantesWeb-BookItalic.woff') format('woff');
    font-weight: 300;
  }
  @font-face {
    font-family:'LotaGrotesqueAlt1-Light';
    src: url('/fonts/LotaGrotesqueAlt1Light/font.woff2') format('woff2'),
      url('/fonts/LotaGrotesqueAlt1Light/font.woff') format('woff');
  }
  @font-face {
    font-family:'LotaGrotesqueAlt1-Regular';
    src: url('/fonts/LotaGrotesqueAlt1Regular/font.woff2') format('woff2'),
      url('/fonts/LotaGrotesqueAlt1Regular/font.woff') format('woff');
  }
  @font-face {
    font-family:'LotaGrotesqueAlt1-SemiBold';
    src: url('/fonts/LotaGrotesqueAlt1SemiBold/font.woff2') format('woff2'),
      url('/fonts/LotaGrotesqueAlt1SemiBold/font.woff') format('woff');
    font-weight: 600;
  }

  ${normalize}

  * {
    box-sizing: border-box;
  }
  *, html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;

    --font-fallback-sans-serif: 'sans-serif';
    --font-fallback-serif: 'serif';
    --font-serif: 'Nantes';
    --font-serif-italic: 'Nantes-Italic';
    --font-sans-serif-light: 'LotaGrotesqueAlt1-Light';
    --font-sans-serif-regular: 'LotaGrotesqueAlt1-Regular';
    --font-sans-serif-semibold: 'LotaGrotesqueAlt1-SemiBold';

    font-size: 16px;

    --font-size-14: 0.875rem; // 14px
    --font-size-16: 1rem; // 16px
    --font-size-18: 1.125rem; // 18px
    --font-size-20: 1.25rem; // 20px
    --font-size-22: 1.375rem; // 22px
    --font-size-24: 1.5rem; // 24px
    --font-size-30: 1.875rem; // 30px
    --font-size-32: 2rem; // 32px
    --font-size-34: 2.125rem; // 34px
    --font-size-36: 2.25rem; // 36px
    --font-size-40: 2.5rem; // 40px
    --font-size-44: 2.75rem; // 44px
    --font-size-46: 2.875rem; // 46px
    --font-size-56: 3.5rem; // 56px
    --font-size-72: 4.5rem; // 72px

    --line-height-22: 1.375rem; // 22px
    --line-height-24: 1.5rem; // 24px
    --line-height-28: 1.75rem; // 28px
    --line-height-30: 1.875rem; // 30px
    --line-height-32: 2; // 32px

    --letter-spacing-ratio-heading: -0.02; // -2% of font size
    --letter-spacing-ratio-eyebrow: 0.05; // 5% of font size
    --line-height-ratio-heading: 1.05; // 105% of font size
    --line-height-ratio-paragraph: 1.2; // 120% of font size

    --font-size-fluid-label-100: var(--font-size-14);
    --line-height-fluid-label-100: calc(var(--font-size-14) * var(--line-height-ratio-paragraph));;
    --font-size-fluid-paragraph-200: var(--font-size-16);
    --line-height-fluid-paragraph-200: calc(var(--font-size-fluid-paragraph-200) * var(--line-height-ratio-paragraph));;
    --font-size-fluid-paragraph-300: var(--font-size-18);
    --line-height-fluid-paragraph-300: calc(var(--font-size-fluid-paragraph-300) * var(--line-height-ratio-paragraph));
    --font-size-fluid-paragraph-400: var(--font-size-20);
    --line-height-fluid-paragraph-400: calc(var(--font-size-fluid-paragraph-400) * var(--line-height-ratio-paragraph));
    --font-size-fluid-heading-500: var(--font-size-24);
    --line-height-fluid-heading-500: calc(var(--font-size-fluid-heading-500) * var(--line-height-ratio-heading));
    --line-height-fluid-heading-500-alt: var(--line-height-30); // sans-serif font therefore doesn't follow line height ratio for headings
    --font-size-fluid-heading-600: var(--font-size-30);
    --line-height-fluid-heading-600: calc(var(--font-size-fluid-heading-600) * var(--line-height-ratio-heading));
    --font-size-fluid-heading-700: var(--font-size-36);
    --line-height-fluid-heading-700: calc(var(--font-size-fluid-heading-700) * var(--line-height-ratio-heading));
    --font-size-fluid-heading-800: var(--font-size-40);
    --line-height-fluid-heading-800: calc(var(--font-size-fluid-heading-800) * var(--line-height-ratio-heading));
    --font-size-fluid-heading-900: var(--font-size-46);
    --line-height-fluid-heading-900: calc(var(--font-size-fluid-heading-900) * var(--line-height-ratio-heading));
    --font-size-fluid-display-1000: var(--font-size-72);
    --line-height-fluid-display-1000: calc(var(--font-size-fluid-display-1000) * var(--line-height-ratio-heading));

    ${colours}
    ${gridSpacing}
    ${radius}
    ${formFieldStyles}
    ${buttonStyles}
  }
  body {
    margin: 0;
    background: ${theme.v2.surface.white};
    color: ${theme.v2.onSurface.text};
    transition: all 0.3s ease;
    font-family:  LotaGrotesqueAlt1-Regular;
    font-weight: 300;

    &.no-scroll {
      overflow: hidden;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  a {
    text-decoration: none;
    color: ${theme.v2.interactive.bright};
    &:hover {
      color: ${theme.v2.interactive.subdued};
    }
  }
  strong {
    font-weight: 600;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: 300;
  }
  h1 {
    font-size: 40px;
    line-height: 54px;
    letter-spacing: -0.1px;
  }
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
  }
  h4 {
    font-size: 20px;
    line-height: 26px;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
  }
  p {
    font-size: 20px;
    line-height: 34px;
    &.small {
      font-size: 16px;
      line-height: 24px;
    }
    &.secondary {
      color: ${theme.colours.secondary};
    }
  }
  hr {
    color: ${theme.colours.backgroundTertiary};
  }
  figure {
    margin:0;
  }
  .caption {
    color: ${theme.colours.secondary};
    font-size: 16px;
    line-height: 26px;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${theme.colours.backgroundTertiary};
    margin: 1em 0;
    padding: 0;
  }
  form {
    div.error {
      color: ${theme.colours.brand};
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  .checkout-v2 .StripeElement {
    background-color: ${theme.colours.white};
  }

  .StripeElement {
    padding: 20px 14px;
    border: 1px solid ${theme.v2.onSurface.subdued.border};
    background-color: ${theme.v2.surface.cream100};
    font-size: ${theme.v2.typography.body.xs.fontSize};
    line-height: ${theme.v2.typography.body.xs.lineHeight};
    font-family: ${theme.v2.font.lotaGrotesqueLight};
    padding: 16px 12px;
    background-color: ${theme.v2.surface.cream100};
    border-radius: ${isPaymentElementEnabled ? '8px' : '4px'}; // TODO: Remove this once we decide to use Payment Element or Card Element
    color: ${theme.v2.onSurface.text};

    .ElementsApp, .ElementsApp .InputElement {
      background-color: transparent;
      input {
        background-color: transparent;
      }
      &::placeholder {
        background-color: transparent;
      }
    }
  }
  .cookie-consent {
    background-color: ${theme.v2.surface.cream300};
    font-family: ${theme.v2.font.lotaGrotesqueLight};
    padding: 1.2em;
    border-radius: 5px;
    max-width: 24em;
    position: fixed;
    bottom: 1em;
    right: 1em;
    font-size: 16px;
    line-height: 1.5em;
    z-index: 999;
    a {
      color: rgb(36, 38, 43);
      text-decoration: underline;
    }
    &-copy {
      margin-bottom: 15px;
    }
    &-buttons {
      display: flex;
      align-items: center;
      align-content: space-between;
      margin-top: 20px;
    }
    #rcc-decline-button {
      display: block;
      padding: 12px 20px;
      font-size: ${theme.v2.typography.body.xs.fontSize};
      font-family: ${theme.v2.font.lotaGrotesqueSemiBold};
      border: none;
      text-align: center;
      white-space: nowrap;
      background: none;
      width: 50%;
      cursor: pointer;
    }
    #rcc-confirm-button {
      display: block;
      padding: 12px 20px;
      font-size: ${theme.v2.typography.body.xs.fontSize};
      font-family: ${theme.v2.font.lotaGrotesqueSemiBold};
      border: none;
      text-align: center;
      white-space: nowrap;
      background-color: ${theme.v2.button.primary.bgColor};
      width: 50%;
      border-radius: 4px;
      color: ${theme.v2.button.primary.textColor};
      cursor: pointer;
      &:hover {
        background-color: ${theme.v2.button.primary.hoverColor};
      }
    }
  }

  //Mobile
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    html {
      --font-size-fluid-heading-500: var(--font-size-22);
      --font-size-fluid-heading-700: var(--font-size-32);
      --font-size-fluid-heading-800: var(--font-size-34);
      --font-size-fluid-heading-900: var(--font-size-44);
      --font-size-fluid-display-1000: var(--font-size-56);
    }
    h1 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0;
    }
    h2 {
      font-size: 20px;
      line-height: 26px;
    }
    h3 {
      font-size: 18px;
      line-height: 24px;
    }
    h4 {
      font-size: 18px;
      line-height: 24px;
    }
    p {
      font-size: ${theme.v2.typography.body.xs.fontSize};
      line-height: ${theme.v2.typography.body.xs.lineHeight};
      &.small {
        font-size: ${theme.v2.typography.body.xxs.fontSize};
        line-height: ${theme.v2.typography.body.xxs.lineHeight};
      }
    }
    .caption {
      font-size: 14px;
      line-height: 24px;
    }
    .cookie-consent {
      bottom: 0;
      right: 0;
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

`
